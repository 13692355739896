<template>
  <div
    class="d-flex panel-filter-item"
    v-ripple
    style="cursor: pointer"
    @click="input"
  >
    <div>
      <v-simple-checkbox
        :ripple="false"
        color="var(--v-primary-lighten1)"
        style="margin-right: 0"
        :value="value"
        @click="input"
      />
    </div>
    <div>{{ label }}</div>
  </div>
</template>
<script>
export default {
  props: {
    label: { type: String, default: "Label" },
    value: { type: Boolean, default: false },
    id: { type: Number, default: 0 },
  },
  methods: {
    input() {
      this.$emit("input", { value: !this.value, id: this.id });
    },
  },
};
</script>
<style lang="scss">
.panel-filter-item {
  font-size: 13px;
  color: var(--v-text-base);
  font-weight: 400;
  padding: 3px 0;
}
</style>
